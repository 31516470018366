import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from '../services/axios';
import './Listings.css';
import AuthContext from '../context/AuthContext';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { ReactComponent as BedroomIcon } from '../assets/icons/bedroom-3.svg';
import { ReactComponent as BathroomIcon } from '../assets/icons/bathroom.svg';
import { ReactComponent as SquareFeetIcon } from '../assets/icons/squarefeet.svg';
import ListingDetail from './ListingDetail';
import { FaShareAlt } from 'react-icons/fa';

const geocodeCache = new Map();

const Listings = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000000 });
  const [showFilters, setShowFilters] = useState(false);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const toggleMobileFilters = () => {
    setShowMobileFilters(!showMobileFilters);
  };
  const [selectedCity, setSelectedCity] = useState('All');
  const [shareText, setShareText] = useState('Share');
  const [selectedBedrooms, setSelectedBedrooms] = useState('Any');
  const [selectedBathrooms, setSelectedBathrooms] = useState('Any');
  const [searchQuery, setSearchQuery] = useState('');
  const [hoveredListing, setHoveredListing] = useState(null);
  const [selectedListing, setSelectedListing] = useState(null);
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [totalListings, setTotalListings] = useState(0);
  const [initialCities] = useState([
    'Toronto', 'Ottawa', 'Mississauga', 'Brampton', 'Hamilton', 'London', 'Markham', 'Vaughan', 'Kitchener', 'Oakville',
'Adjala-Tosorontio', 'Ajax', 'Aurora', 'Barrie', 'Brock', 'Burlington', 'Caledon', 'Cambridge', 'Centre Wellington', 'Chatsworth', 
'Clarington', 'Collingwood', 'East Gwillimbury', 'East Luther Grand Valley', 'Erin', 'Essa', 'Fort Erie', 'Galway-Cavendish and Harvey', 
'Gananoque', 'Georgian Bay', 'Georgian Bluffs', 'Georgina', 'Grimsby', 'Guelph', 'Guelph/Eramosa', 'Haldimand', 'Halton Hills', 
'Innisfil', 'Kawartha Lakes', 'King', 'Kingston', 'Lincoln', 'Midland', 'Milton', 'New Tecumseth', 'Newmarket', 'Niagara Falls', 
'Niagara-on-the-Lake', 'Orangeville', 'Orillia', 'Oro-Medonte', 'Oshawa', 'Owen Sound', 'Penetanguishene', 'Peterborough', 
'Pickering', 'Port Colborne', 'Scugog', 'Severn', 'Shelburne', 'Tiny', 'Wainfleet', 'Wasaga Beach', 'Waterloo', 'Welland', 
'Whitby', 'Whitchurch-Stouffville', 'Woolwich'

  ]);
  const [initialBedrooms] = useState([...Array(9).keys()].map((num) => (num + 1).toString()));
  const [initialBathrooms] = useState([...Array(10).keys()].map((num) => (num + 1).toString()));
  const [initialZoomLevel] = useState(8);
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markerClusterer = useRef(null);
  const listingsPerPage = 18;
  const maxListingsToShow = 100;
  const maxTotalListings = 70000;

  const { user } = useContext(AuthContext);

  

  useEffect(() => {
    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);

    // Set state based on URL parameters
    const city = queryParams.get('city') || 'All';
    const minPrice = parseInt(queryParams.get('minPrice'), 10) || 0;
    const maxPrice = parseInt(queryParams.get('maxPrice'), 10) || 10000000;
    const bedrooms = queryParams.get('bedrooms') || 'Any';
    const bathrooms = queryParams.get('bathrooms') || 'Any';
    const search = queryParams.get('search') || '';
    const page = parseInt(queryParams.get('page'), 10) || 1;

    setSelectedCity(city);
    setPriceRange({ min: minPrice, max: maxPrice });
    setSelectedBedrooms(bedrooms);
    setSelectedBathrooms(bathrooms);
    setSearchQuery(search);
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    const fetchPageListings = async () => {
      setLoading(true);
      try {
        const pageOffset = (currentPage - 1) * listingsPerPage;
        const pageResponse = await axios.get('/listings', {
          params: {
            limit: listingsPerPage,
            offset: pageOffset,
            city: selectedCity !== 'All' ? selectedCity : null,
            bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
            bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
            search: searchQuery || null,
            minPrice: priceRange.min,
            maxPrice: priceRange.max
          },
        });

        setFilteredListings(pageResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching page listings:', error);
        setLoading(false);
      }
    };

    fetchPageListings();
  }, [currentPage, selectedCity, selectedBedrooms, selectedBathrooms, searchQuery, priceRange]);

  useEffect(() => {
    // After listings are loaded, update currentPage based on query params if necessary
    const queryParams = new URLSearchParams(window.location.search);
    const pageFromURL = parseInt(queryParams.get('page'), 10);
    if (pageFromURL && pageFromURL !== currentPage) {
      setCurrentPage(pageFromURL); // Correct the page after data is loaded
    }
  }, [loading]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const queryParams = new URLSearchParams({
      city: selectedCity,
      minPrice: priceRange.min,
      maxPrice: priceRange.max,
      bedrooms: selectedBedrooms,
      bathrooms: selectedBathrooms,
      search: searchQuery,
      page: pageNumber
    }).toString();
    window.history.pushState(null, '', `/listings?${queryParams}`);

    // Scroll to top of the page when page changes
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Smooth scroll to top
  });
  };

  const toggleFilters = () => {
  setShowFilters((prevShowFilters) => !prevShowFilters);
};


  const renderPagination = () => {
    const totalPages = Math.ceil(totalListings / listingsPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return (
      <div>
        {pageNumbers.map(page => (
          <button
            key={page}
            className={currentPage === page ? 'active' : ''}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
      </div>
    );
  };
  

  useEffect(() => {
    const fetchClusterListings = async () => {
      try {
        const clusterResponse = await axios.get('/listings', {
          params: {
            limit: maxListingsToShow,
            city: selectedCity !== 'All' ? selectedCity : null,
            bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
            bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
            search: searchQuery || null,
            minPrice: priceRange.min,
            maxPrice: priceRange.max
          },
        });

        const totalResponse = await axios.get('/listings/count', {
          params: {
            city: selectedCity !== 'All' ? selectedCity : null,
            bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
            bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
            search: searchQuery || null,
            minPrice: priceRange.min,
            maxPrice: priceRange.max
          },
        });

        const totalCount = Math.min(totalResponse.data.totalCount, maxTotalListings);
        setTotalListings(totalCount);

        if (isFirstLoad || currentPage === 1) {
          updateClusters(clusterResponse.data);
          setIsFirstLoad(false);
        }

      } catch (error) {
        console.error('Error fetching cluster listings:', error);
      }
    };

    fetchClusterListings();
  }, [selectedCity, selectedBedrooms, selectedBathrooms, searchQuery, priceRange, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setFilteredListings([]);

    if (mapInstance.current) {
      mapInstance.current.setZoom(initialZoomLevel);
    }
  }, [selectedCity, selectedBedrooms, selectedBathrooms, searchQuery, priceRange]);
  

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyAzeCUuExS1-_9uwphZn8UKOgZZl2jBrSA',
      version: 'weekly',
      libraries: ['geometry', 'places'],
    });

    const mapStyle = [
      {
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#bdbdbd' }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#d5d5d5' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#000' }],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#000' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#c9c9c9' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
    ];

    loader.load().then(() => {
      if (mapRef.current) {
        mapInstance.current = new window.google.maps.Map(mapRef.current, {
          center: { lat: 43.6532, lng: -79.3832 },
          zoom: initialZoomLevel,
          mapTypeId: 'roadmap',
          styles: mapStyle,
        });

        if (filteredListings.length > 0) {
          updateClusters(filteredListings.slice(0, 5));
        }
      }
    });
  }, []);

  const updateClusters = async (listingsToMark) => {
    if (markerClusterer.current) {
      markerClusterer.current.clearMarkers();
      markerClusterer.current = null;
    }

    const validMarkers = [];

    // Loop through listings and use coordinates if available
    for (let i = 0; i < listingsToMark.length; i++) {
      const listing = listingsToMark[i];
      const { coordinates, address, ml_num } = listing;

      // Use coordinates from the SQL database
      if (coordinates) {
        const [lat, lng] = coordinates.split(',').map(Number);
        const position = { lat, lng };

        // Add marker if position is valid
        if (!isNaN(lat) && !isNaN(lng)) {
          const marker = new window.google.maps.Marker({
            position,
            title: address,
          });

          marker.addListener('click', () => {
            setSelectedListing(listing);
          });

          validMarkers.push(marker);
        } else {
          console.warn(`Invalid coordinates for listing MLS#: ${ml_num}`);
        }
      } else {
        console.warn(`Coordinates not available for listing MLS#: ${ml_num}`);
      }
    }
  
    console.log(`Adding ${validMarkers.length} markers to the cluster...`);
  
    // Create the marker cluster with valid markers
    markerClusterer.current = new MarkerClusterer({
      map: mapInstance.current,
      markers: validMarkers,
      renderer: {
        render: ({ count, position }) => {
          const svg = window.btoa(
            `<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
              <circle cx="32" cy="32" r="28" fill="#9CDCF8" opacity="0.6" stroke="rgba(0, 0, 0, 0.2)" stroke-width="1"/>
              <circle cx="32" cy="32" r="22" fill="#9CDCF8" opacity="0.8" stroke="rgba(0, 0, 0, 0.5)" stroke-width="1"/>
              <circle cx="32" cy="32" r="16" fill="#9CDCF8" stroke="black" stroke-width="1"/>
              <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="14px" font-family="Inter, sans-serif" fill="black">${count}</text>
            </svg>`
          );
          return new window.google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new window.google.maps.Size(48, 48),
            },
          });
        },
      },
    });
  
    console.log('Cluster creation complete.');
  };
  
  
  

  const formatPrice = (price) => {
    return `$${Number(price).toLocaleString()}`;
  };

  const handleFilterChange = (setter) => (value) => {
    setter(value);
  };

  const totalPages = Math.ceil(totalListings / listingsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  
    // Update the URL to reflect the new page
    const queryParams = new URLSearchParams({
      city: selectedCity,
      minPrice: priceRange.min,
      maxPrice: priceRange.max,
      bedrooms: selectedBedrooms,
      bathrooms: selectedBathrooms,
      search: searchQuery,
      page: pageNumber  // Update the URL with the new page number
    }).toString();
  
    // Push the updated query string to the URL (without reloading the page)
    window.history.pushState(null, '', `/listings?${queryParams}`);

     // Scroll to top of the page when page changes
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Smooth scroll to top
  });
  };
  

  const getPageNumbers = () => {
    const maxPages = 7;
    let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
    let endPage = startPage + maxPages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  const uniqueCities = initialCities;

  const clearFilters = () => {
    setPriceRange({ min: 0, max: 10000000 });
    setSelectedCity('All');
    setSelectedBedrooms('Any');
    setSelectedBathrooms('Any');
    setSearchQuery('');
    setCurrentPage(1);
    setFilteredListings([]);
  };

  const removeFilter = (filterType) => {
    if (filterType === 'city') {
      setSelectedCity('All');
    } else if (filterType === 'price') {
      setPriceRange({ min: 0, max: 10000000 });
    } else if (filterType === 'bedrooms') {
      setSelectedBedrooms('Any');
    } else if (filterType === 'bathrooms') {
      setSelectedBathrooms('Any');
    } else if (filterType === 'search') {
      setSearchQuery('');
    }
    setCurrentPage(1);
    setFilteredListings([]);
  };

  const handleImageClick = (e, listing) => {
    e.stopPropagation();
    
    const shouldBlur = listing.search_type !== 'IDX' && !user;
  
    if (shouldBlur) {
      // Save current filters and page in the URL
      const queryParams = new URLSearchParams({
        city: selectedCity,
        minPrice: priceRange.min,
        maxPrice: priceRange.max,
        bedrooms: selectedBedrooms,
        bathrooms: selectedBathrooms,
        search: searchQuery,
        page: currentPage
      }).toString();
  
      // Redirect to login with the correct base URL and query parameters
      const loginUrl = `/login?redirect=${encodeURIComponent(`/listings?${queryParams}`)}`;
      
      window.location.href = loginUrl;  // Redirect the user
      return;
    }
  
    setSelectedListing(listing);
  };
  
  

  const renderImage = (listing) => {
    const mainImage = listing.main_image || (Array.isArray(listing.photo_urls) ? listing.photo_urls[0] : listing.photo_urls.split(',')[0]);
  
    // Determine if we should blur the image based on search_type
    const shouldBlur = listing.search_type !== 'IDX' && !user;
  
    return (
      <div className='image-container'>
        <img
          src={mainImage}
          alt="Listing"
          className={`listing-image ${shouldBlur ? 'blurred' : ''}`}
          onClick={(e) => {
            if (!shouldBlur) {
              handleImageClick(e, listing);
            }
          }}
        />
        {shouldBlur && (
          <div className="login-button-overlay">
            <a
              href="#"
              className="login-button"
              onClick={(e) => {
                e.preventDefault();
  
                // Log the MLS number to verify that we have it
                console.log("MLS Number (ml_num):", listing.ml_num);
  
                // Create URLSearchParams for the current filters, page, and MLS number
                const queryParams = new URLSearchParams({
                  city: selectedCity,
                  minPrice: priceRange.min,
                  maxPrice: priceRange.max,
                  bedrooms: selectedBedrooms,
                  bathrooms: selectedBathrooms,
                  search: searchQuery,
                  page: currentPage,
                  ml_num: listing.ml_num  // Append the MLS number correctly
                });
  
                // Convert queryParams to a string for the URL
                const queryString = queryParams.toString();
  
                // Log the query string to confirm ml_num is included
                console.log("Query String with MLS Number:", queryString);
  
                // Build the login URL and ensure encoding is correct
                const loginUrl = `/login?redirect=${encodeURIComponent(`/listings?${queryString}`)}`;
  
                // Log the final login URL
                console.log("Generated Login URL:", loginUrl);
  
                // Redirect to login page with the constructed URL
                window.location.href = loginUrl;
              }}
            >
              Login to view details
            </a>
          </div>
        )}
      </div>
    );
  };
  
  
  

  return (
    <div className='listing-search-container'>
      {error && (
        <div className='error-message'>
          Error fetching listings data: {error}
        </div>
      )}
      

      <div className='filter-bar'>
        <div className='filter-item price-range-filter'>
          <label>Price Range:</label>
          <div className="price-inputs">
            <input
              type="number"
              placeholder="Min"
              value={priceRange.min}
              onChange={(e) => handleFilterChange(setPriceRange)({ ...priceRange, min: e.target.value })}
              className="price-input"
            />
            <span> - </span>
            <input
              type="number"
              placeholder="Max"
              value={priceRange.max}
              onChange={(e) => handleFilterChange(setPriceRange)({ ...priceRange, max: e.target.value })}
              className="price-input"
            />
          </div>
        </div>
        <div className='filter-item'>
          <label>City:</label>
          <select
            value={selectedCity}
            onChange={(e) => handleFilterChange(setSelectedCity)(e.target.value)}
            className='city-select'
          >
            <option value='All'>Select City</option>
            {uniqueCities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className='filter-item'>
          <label>Bedrooms:</label>
          <select
            value={selectedBedrooms}
            onChange={(e) => handleFilterChange(setSelectedBedrooms)(e.target.value)}
            className='bedrooms-select'
          >
            <option value='Any'>Any</option>
            {initialBedrooms.map((bedrooms, index) => (
              <option key={index} value={bedrooms}>
                {bedrooms}
              </option>
            ))}
          </select>
        </div>
        <div className='filter-item'>
          <label>Bathrooms:</label>
          <select
            value={selectedBathrooms}
            onChange={(e) => handleFilterChange(setSelectedBathrooms)(e.target.value)}
            className='bathrooms-select'
          >
            <option value='Any'>Any</option>
            {initialBathrooms.map((bathrooms, index) => (
              <option key={index} value={bathrooms}>
                {bathrooms}
              </option>
            ))}
          </select>
        </div>
        
        
        <div className='filter-item search-filter'>
          <label>Search Address:</label>
          <input
            type='text'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder='Search Address, Street Name, or Listing #'
            className='search-input'
          />
        </div>
      </div>
      {/* Mobile-specific filter section */}
      <div className='mobile-filter-section'>
        <button className='filter-toggle-btn' onClick={toggleMobileFilters}>
          Filter Your Search →
        </button>
        {showMobileFilters && (
          <div className='mobile-filter-options'>
            <div className='filter-item price-range-filter'>
              <label>Price Range:</label>
              <div className="price-inputs">
                <input
                  type="number"
                  placeholder="Min"
                  value={priceRange.min}
                  onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}
                  className="price-input"
                />
                <span> - </span>
                <input
                  type="number"
                  placeholder="Max"
                  value={priceRange.max}
                  onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })}
                  className="price-input"
                />
              </div>
            </div>
            <div className='filter-item'>
              <label>City:</label>
              <select
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                className='city-select'
              >
                <option value='All'>Select City</option>
                {uniqueCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
            <div className='filter-item'>
              <label>Bedrooms:</label>
              <select
                value={selectedBedrooms}
                onChange={(e) => setSelectedBedrooms(e.target.value)}
                className='bedrooms-select'
              >
                <option value='Any'>Any</option>
                {initialBedrooms.map((bedrooms, index) => (
                  <option key={index} value={bedrooms}>
                    {bedrooms}
                  </option>
                ))}
              </select>
            </div>
            <div className='filter-item'>
              <label>Bathrooms:</label>
              <select
                value={selectedBathrooms}
                onChange={(e) => setSelectedBathrooms(e.target.value)}
                className='bathrooms-select'
              >
                <option value='Any'>Any</option>
                {initialBathrooms.map((bathrooms, index) => (
                  <option key={index} value={bathrooms}>
                    {bathrooms}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
      <div className='applied-filters-bar'>
        {selectedCity !== 'All' && (
          <button
            onClick={() => removeFilter('city')}
            className='filter-button'
          >
            City: {selectedCity} <span className='remove-filter'>X</span>
          </button>
        )}
        {(priceRange.min !== 0 || priceRange.max !== 10000000) && (
          <button
            onClick={() => removeFilter('price')}
            className='filter-button'
          >
            Price: {formatPrice(priceRange.min)} - {formatPrice(priceRange.max)}{' '}
            <span className='remove-filter'>X</span>
          </button>
        )}
        {selectedBedrooms !== 'Any' && (
          <button
            onClick={() => removeFilter('bedrooms')}
            className='filter-button'
          >
            Bedrooms: {selectedBedrooms} <span className='remove-filter'>X</span>
          </button>
        )}
        {selectedBathrooms !== 'Any' && (
          <button
            onClick={() => removeFilter('bathrooms')}
            className='filter-button'
          >
            Bathrooms: {selectedBathrooms} <span className='remove-filter'>X</span>
          </button>
        )}
        {searchQuery && (
          <button
            onClick={() => removeFilter('search')}
            className='filter-button'
          >
            Search: {searchQuery} <span className='remove-filter'>X</span>
          </button>
        )}
        {(selectedCity !== 'All' ||
          priceRange.min !== 0 ||
          priceRange.max !== 10000000 ||
          selectedBedrooms !== 'Any' ||
          selectedBathrooms !== 'Any' ||
          searchQuery) && (
          <button onClick={clearFilters} className='clear-filters-button'>
            Clear All
          </button>
        )}
      </div>
      <div className='main-content'>
        <div className='map-column'>
          <div ref={mapRef} className='map-placeholder'></div>
        </div>
        <div className='listings-column'>
          <div className={`listing-grid ${isFirstLoad ? 'transition-container' : ''}`}>
            {loading ? (
              Array.from({ length: listingsPerPage }).map((_, index) => (
                <div key={index} className="listing-card loading-card">
                  {/* The loading animation is applied here */}
                </div>
              ))
            ) : filteredListings.length > 0 ? (
              filteredListings.map((listing) => {
                console.log(`Listing ${listing.ml_num} has search_type: ${listing.search_type}`);

                // Only blur listings that do not have search_type as 'IDX'
                const shouldBlur = listing.search_type !== 'IDX' && !user;
              
                return (
                  <div
                    key={listing.ml_num}
                    className={`listing-card ${shouldBlur ? 'blurred' : ''}`} // Apply 'blurred' class if not IDX
                    onClick={(e) => handleImageClick(e, listing)}
                    onMouseEnter={() => setHoveredListing(listing)}
                    onMouseLeave={() => setHoveredListing(null)}
                  >
                    {/* Render the image */}
                    {renderImage(listing)}
              
                    {/* Blur the listing content if necessary */}
                    <div className={`listing-content ${shouldBlur ? 'blurred' : ''}`}>
                      {/* The rest of your listing content goes here */}
                      <div className="listing-style-badge">
                        <span><a href="/contact">Buy and Get $5000 Cash Back</a></span>
                      </div>
              
                      <div className="listing-details">
                        <div className="listing-detail-item">
                          <BedroomIcon className="icon" />
                          <span className="with-words"> {listing.bedrooms || 'N/A'}</span>
                          <span className="without-words">{listing.bedrooms || 'N/A'}</span>
                        </div>
                        <div className="listing-detail-item">
                          <BathroomIcon className="icon" />
                          <span className="with-words"> {listing.bathrooms || 'N/A'}</span>
                          <span className="without-words">{listing.bathrooms || 'N/A'}</span>
                        </div>
                        <div className="listing-detail-item">
                          SQFT: {listing.square_feet || 'N/A'}
                        </div>
                      </div>
              
                      <div className="listing-title">
                        <div className="title-community">
                          <h3 className="street-address"> {listing.address} {listing.unit_num ? `#${listing.unit_num}` : ''}</h3>
                          <p className="community">
                            {listing.community}{' '}
                            <span className="separator-dot">•</span>{' '}
                            {listing.municipality}
                          </p>
                        </div>
                        <div className="listing-price">
                          {formatPrice(listing.price)}
                        </div>
                      </div>
                    </div>
              
                    {/* Optional: Show overlay if blurred */}
                    {!user && (listing.search_type === 'NULL' || listing.search_type === 'VOW') && (
                      <div className="login-button-overlay">
                        <a href="/login" className="login-button">
                          Login to View Listing
                        </a>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div>No listings found for your criteria.</div>
            )}
          </div>

          <div className='pagination'>
            {currentPage > 1 && (
              <button onClick={() => paginate(currentPage - 1)}>&laquo;</button>
            )}

            {pageNumbers[0] > 1 && (
              <>
                <button onClick={() => paginate(1)}>1</button>
                <span>...</span>
              </>
            )}

            {pageNumbers.map((page) => (
              <button
                key={page}
                onClick={() => paginate(page)}
                className={`pagination-button ${
                  currentPage === page ? 'active' : ''
                }`}
              >
                {page}
              </button>
            ))}

            {pageNumbers[pageNumbers.length - 1] < totalPages && (
              <>
                <span>...</span>
                <button onClick={() => paginate(totalPages)}>
                  {totalPages}
                </button>
              </>
            )}

            {currentPage < totalPages && (
              <button onClick={() => paginate(currentPage + 1)}>&raquo;</button>
            )}
          </div>
        </div>
      </div>

      {selectedListing && (
        <>
          <div
            className='listing-popup-overlay'
            onClick={() => setSelectedListing(null)}
          ></div>
          <div className='listing-popup'>
            <div className='banner'>
              <div className='banner-left'>
                <a href="#"
                  className='share-link'
                  onClick={(e) => {
                    e.preventDefault();

                    const addressSlug = selectedListing.address
                      .replace(/ /g, '-') 
                      .replace(/[^a-zA-Z0-9\-]/g, ''); 

                    const unitNum = selectedListing.unit_num ? `-${selectedListing.unit_num}` : '';

                    const link = `${window.location.origin}/listing/${selectedListing.ml_num}/${addressSlug}${unitNum}`;

                    navigator.clipboard.writeText(link).then(() => {
                      setShareText("Copied Listing Link to Clipboard");

                      setTimeout(() => {
                        setShareText("Share");
                      }, 3000);
                    });
                  }}>
                  <span>{shareText}</span> <FaShareAlt className='banner-icon' />
                </a>
              </div>
              <div className='banner-middle'>
                {[
                  { name: 'Overview', anchor: '#overview' },
                  { name: 'Sold/Listing History', anchor: '#listing-history' },
                  { name: 'Facts & Features', anchor: '#facts-features' },
                  { name: 'Payment Calculator', anchor: '#payment-calculator' },
                ].map((item, index) => (
                  <a key={index} href={item.anchor} className='banner-menu-item'>
                    {item.name}
                  </a>
                ))}
              </div>
              <div className='banner-right'>
                <button
                  className='popup-close-button'
                  onClick={() => setSelectedListing(null)}
                >
                  X
                </button>
              </div>
            </div>

            <div className='popup-content'>
              <ListingDetail ml_num={selectedListing.ml_num} />
            </div>
          </div>
        </>
      )}
      
    </div>
  );
};

export default Listings;
